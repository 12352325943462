@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Varela Round', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-y: auto;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(42, 42, 42, 1);
}

* {
  box-sizing: border-box;
  margin: 0;
}

ul {
  list-style: none;
}
