@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Varela Round', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-y: auto;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(42, 42, 42, 1);
}

* {
  box-sizing: border-box;
  margin: 0;
}

ul {
  list-style: none;
}

.app .heading .banner-container[data-v-56f39059]{width:100%;height:40vh;background:#2a2a2a;background:linear-gradient(180deg, #2a2a2a 0%, #464646 100%);display:flex;justify-content:center;align-items:center;flex-direction:column}.app .heading .banner-container h1[data-v-56f39059]{color:#fff;font-size:55px}.app .heading .banner-container h2[data-v-56f39059]{color:#fff;font-size:35px}.app button[data-v-56f39059]:hover{cursor:pointer}
.react-pdf__Page__canvas{position:relative;left:50%;-webkit-transform:translate(-50%, 0%);transform:translate(-50%, 0%);box-shadow:0px 0px 10px 1px rgba(0,0,0,.2);border-radius:10px;margin-bottom:50px}.react-pdf__Page__annotations{height:0}.react-pdf__message{display:none}footer{width:100%;padding:30px 25%;background-color:#2a2a2a;color:#fff;text-align:center;font-size:12px}
.container[data-v-4febbe93]{width:100%;overflow:hidden}.container .body section[data-v-4febbe93]{width:100%;overflow:hidden;padding:100px 25% 100px 25%;font-size:20px}.container .body section .section-header[data-v-4febbe93]{width:100%;padding:0px 0px 5px 0px}.container .body section .section-header h2[data-v-4febbe93]{color:#f6a723;font-size:1.4em}.container .body section .section-body[data-v-4febbe93]{width:100%}.container .body section .section-body h3[data-v-4febbe93]{padding:20px 0px 5px 0px;font-size:1.1em}.container .body section .section-body p[data-v-4febbe93]{font-size:.9em}.container .body section .section-body p strong[data-v-4febbe93]{font-weight:bold;line-height:50px;font-size:.89em}.container .body section .section-body p img[data-v-4febbe93]{width:100%}.container .body section .section-body p .design-image[data-v-4febbe93]{width:60%;position:relative;left:50%;-webkit-transform:translate(-50%, 0%);transform:translate(-50%, 0%);z-index:1}
.container .file-heading[data-v-96a13a26]{position:relative;width:100%;overflow:hidden;padding:100px 30% 100px 30%;display:flex;align-items:center;flex-direction:column}.container .file-heading h1[data-v-96a13a26]{font-size:40px}.container .file-heading a[data-v-96a13a26]{border:none;background-color:rgba(0,0,0,0);padding:10px;font-size:15px}.container .file-container[data-v-96a13a26]{position:relative;display:flex;height:100vh;width:100vw;justify-content:center}
.container .file-heading[data-v-37f4d67d]{position:relative;width:100%;overflow:hidden;padding:100px 30% 100px 30%;display:flex;align-items:center;flex-direction:column}.container .file-heading h1[data-v-37f4d67d]{font-size:40px}.container .file-heading a[data-v-37f4d67d]{border:none;background-color:rgba(0,0,0,0);padding:10px;font-size:15px}.container .file-container[data-v-37f4d67d]{position:relative;display:flex;height:100vh;width:100vw;justify-content:center}
.container .file-heading[data-v-05508bfd]{position:relative;width:100%;overflow:hidden;padding:100px 30% 100px 30%;display:flex;align-items:center;flex-direction:column}.container .file-heading h1[data-v-05508bfd]{font-size:40px}.container .file-heading a[data-v-05508bfd]{border:none;background-color:rgba(0,0,0,0);padding:10px;font-size:15px}.container .file-container[data-v-05508bfd]{position:relative;display:flex;height:100vh;width:100vw;justify-content:center}
.container .file-heading[data-v-d4e7212e]{position:relative;width:100%;overflow:hidden;padding:100px 30% 100px 30%;display:flex;align-items:center;flex-direction:column}.container .file-heading h1[data-v-d4e7212e]{font-size:40px}.container .file-heading a[data-v-d4e7212e]{border:none;background-color:rgba(0,0,0,0);padding:10px;font-size:15px}.container .file-container[data-v-d4e7212e]{position:relative;display:flex;height:100vh;width:100vw;justify-content:center}
header[data-v-cb883ab8]{background-color:rgba(0,0,0,0);color:#fff;padding:20px 10%;position:fixed;top:0;left:0;width:100vw;transition:.2s background-color,padding .2s;z-index:99}header ul[data-v-cb883ab8]{display:flex;padding:0;justify-content:center}header ul li[data-v-cb883ab8]{font-size:18px;padding:5px 15px}header ul li a[data-v-cb883ab8]{color:#fff;text-decoration:none;transition:.2s color}header ul li[data-v-cb883ab8]:hover{cursor:pointer}header ul li:hover a[data-v-cb883ab8]{color:rgba(255,255,255,.6)}header .dropdown .dropdown-menu[data-v-cb883ab8]{border:none;background-color:rgba(0,0,0,0);color:#fff;font-size:18px;padding:5px}header .dropdown .dropdown-body[data-v-cb883ab8]{max-height:0px;overflow:hidden;transition:max-height .2s;position:absolute;width:100%}.header-color-switched[data-v-cb883ab8]{background-color:#272727;padding:10px 10%}
